import "./homeCard.css";
import { Player } from "@lottiefiles/react-lottie-player";

export default function HomeCard({ animationLink, cardHeading, cardContent }) {
  return (
    <main>
      <div className="card">
        <Player
          src={animationLink}
          loop
          autoplay
          style={{ height: "250px" }}
          className="player"
        />
      </div>
      <div className="card-content">
          <h3>{cardHeading}</h3>
        </div>
    </main>
  );
}
