import React, { useState } from "react";
import { Link,useLocation } from "react-router-dom";
import "./staticnavbar.css";

export default function StaticMainbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClick=()=>{
    setShowPopup(false);
  }

  return (
    <div className={`staticmainbar_whole ${isOpen ? "open" : ""}`}>
      <div className="staticmainbar_top">
        <div href="/" className="staticmainbar_company">
          <Link to="/">Rigvedita Technologies</Link>
        </div>

        {isOpen ? (
          <div className="staticmainbar_tabs_mobile">
            <div className="staticmainbar_tab">
              <Link
                to={location.pathname === '/team' ? '/' : '/team'}
                className="staticmainbar_link nav-link"
                onClick={toggleMenu}
              >
                {location.pathname === '/team' ? "Home" : "Team"}
              </Link>
            </div>
          </div>
        ) : (
          <div className="staticmainbar_tabs">
            <div className="staticmainbar_tab">
              <Link to="/" className="staticmainbar_link nav-link">
                Home
              </Link>
            </div>
          </div>
        )}

        <div className="staticmainbar_button">
          <button onClick={toggleMenu} className="hamburger">
            ☰
          </button>
        </div>
      </div>
      {showPopup && (
        <>
        <div className="staticmainbar_overlay" onClick={handleClick}></div>
        <div className="staticmainbar_popup">
          <p className="staticmainbar_popup_text">Products will be available soon!</p>
        </div>
        </>
      )}
    </div>
  );
}
