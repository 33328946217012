import StaticMainbar from "../components/staticnavbar";
// import About from "./About";
import './Team.css';
import arman from "../assets/team/arman.jpeg";
import sir from "../assets/team/sir.jpeg";
import khushi from "../assets/team/khushi.jpeg";
import dhawal from "../assets/defaultIMG.jpg";

export default function Team() {
    return (
        <>
        <StaticMainbar/>
        <div className="Team_Heading">
            <div className="Team_Heading_Content">
                <div className="Team_Heading_1">Meet our Team</div>
            </div>
        </div>
        <div className="Team_Grid">
            <div className="Team_Section">
                <h2 className="Team_Section_Heading">Core Team</h2>
                <div className="Team_sec_2">
                <div className="Team_Member">
                    <img src={dhawal} alt="Team Member 1" className="Team_Member_Image"/>
                    <h3 className="Team_Member_Name">Dhawal Gupta</h3>
                </div>
                <div className="Team_Member">
                    <img src={sir} alt="Team Member 1" className="Team_Member_Image"/>
                    <h3 className="Team_Member_Name">Rimpesh Katiyar</h3>
                </div>
                </div>
            </div>
            <div className="Team_Section">
                <h2 className="Team_Section_Heading">Software Development Team</h2>
                <div className="Team_sec_1">
                <div className="Team_Member">
                    <img src={arman} alt="Team Member 2" className="Team_Member_Image"/>
                    <h3 className="Team_Member_Name">Arman Gupta</h3>
                </div>
                <div className="Team_Member">
                    <img src={khushi} alt="Team Member 3" className="Team_Member_Image"/>
                    <h3 className="Team_Member_Name">Khushi Agrawal</h3>
                </div>
                </div>
            </div>
        </div>
        {/* <About/> */}
        </>
    );
}
