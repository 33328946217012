import React from 'react';
import './Support.css';

export default function Support ({
    heading = "Our Supporters",
    supporters=[]
}) {
    return (
        <div className="support-container">
            <h1 className="support-title">{heading}</h1>
        <div className="supporters">
            {supporters.map((supporter,index)=>(
                <div key={index}>
                    <img className="support-img" src={supporter.logo} alt={supporter.heading}/>
                </div>
            ))}
            </div>
        </div>
    );
}
