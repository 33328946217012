import "./footer.css";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <footer class="footer-area footer--light">
        <div class="footer-big">
          {/* <!-- start .container --> */}
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-12 ">
                <div class="footer-widget">
                  <div class="widget-about">
                    <h1 style={{ color: "#fff" }}>Rigvedita</h1>
                    <p>Welcome to the World of Fluid Simulation</p>
                    <ul class="contact-details">
                      <li>
                        <span class="icon-earphones"></span> Call Us :
                        <a href="tel:0291-280 1291"> 0291-280 1291</a>
                      </li>
                      <li>
                        <span class="icon-envelope-open"></span>
                        <a href="mailto:director.software@rigveditatechnologies.com">
                        director.software@rigveditatechnologies.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- Ends: .footer-widget --> */}
              </div>
              {/* <!-- end /.col-md-4 --> */}
              <div class="col-md-3 col-sm-4">
                <div class="footer-widget">
                  <div class="footer-menu footer-menu--1">
                    <h4 class="footer-widget-title">Address</h4>
                    <p className="footer_address">
                      23/651 Chopasani Housing Board Nandanwan Jodhpur, Rajasthan - 342008 India
                    </p>
                  </div>
                </div>
              </div>

              {/* <div class="col-md-3 col-sm-4">
                <div class="footer-widget">
                  <div class="footer-menu">
                    <h4 class="footer-widget-title">Our Company</h4>
                    <ul>
                      <li>
                        <a href="#">About Us</a>
                      </li>
                      <li>
                        <a href="#">How It Works</a>
                      </li>
                      <li>
                        <a href="#">Affiliates</a>
                      </li>
                      <li>
                        <a href="#">Testimonials</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                      <li>
                        <a href="#">Plan &amp; Pricing</a>
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              {/* <div class="col-md-3 col-sm-4">
                <div class="footer-widget">
                  <div class="footer-menu no-padding">
                    <h4 class="footer-widget-title">Help Support</h4>
                    <ul>
                      <li>
                        <a href="#">Support Forum</a>
                      </li>
                      <li>
                        <a href="#">Terms &amp; Conditions</a>
                      </li>
                      <li>
                        <a href="#">Support Policy</a>
                      </li>
                      <li>
                        <a href="#">Refund Policy</a>
                      </li>
                      <li>
                        <a href="#">FAQs</a>
                      </li>
                      <li>
                        <a href="#">Buyers Faq</a>
                      </li>
                      <li>
                        <a href="#">Sellers Faq</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <!-- end /.row --> */}
          </div>
          {/* <!-- end /.container --> */}
        </div>
        {/* <!-- end /.footer-big --> */}

        <div class="mini-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="copyright-text">
                  <p>
                    © 2024. All rights reserved. Created by
                    <Link to="#" style={{ marginLeft: "7px", color: "#fff" }}>
                      Rigvedita
                    </Link>
                  </p>
                </div>

                {/* <div class="go_top">
                  <span class="icon-arrow-up"></span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
