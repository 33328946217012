import React, { useEffect } from "react";
import "./Navbar.css";

import Mainbar from "../components/mainbar";
import HomeRow from "../components/homeRow";
import HomeCard from "../components/homeCard";
import Support from "../components/Support";
import expertiseCard from "../components/expertiseCard";

import fluid from "../assets/fluid.jpg";
import nidhi from "../assets/supporters/dst_nidhi.png";
import bio from "../assets/animation/bio.json";
import health from "../assets/animation/health.json";
import oil from "../assets/animation/oil and gas.json";
import energy from "../assets/animation/energy.json";

function Navbar() {
  const supportersData = [{ logo: nidhi, heading: "dst nidhi logo" }];
  useEffect(() => {
    const allSections = document.querySelectorAll(".section");

    const revealSection = function (entries, observer) {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      entry.target.classList.remove("section--hidden");
      observer.unobserve(entry.target);
    };

    const sectionObserver = new IntersectionObserver(revealSection, {
      root: null,
      threshold: 0.1,
    });

    allSections.forEach(function (section) {
      sectionObserver.observe(section);
      section.classList.add("section--hidden");
    });

    return () => {
      allSections.forEach((section) => {
        sectionObserver.unobserve(section);
      });
    };
  }, []);

  return (
    <>
      <Mainbar />
      {/* <HomeRow
        imageSource={fluid}
        heading="High Reynolds Number Flow Around a Cylinder in a Duct
"
        rowContent="This simulation examines fluid flow in a rectangular duct with a cylinder, where the Reynolds number is 15,000 based on the duct's characteristic length and 3,000 based on the cylinder's diameter. The study focuses on the complex flow patterns that emerge, including vortex shedding and turbulence, as the fluid interacts with the cylindrical obstruction"
      /> */}
      <div className="mainbar_mid ">
        <div className="mainbar_mid_right">
          <img
            src={fluid}
            className="d-block"
            alt="Fluid Dynamics Simulation"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="mainbar_mid_left">
          <div className="mainbar_mid_left_top_left mb-2">
            High Reynolds Number Flow Around a Cylinder in a Duct
          </div>
          <div className="mainbar_mid_left_top_right">
            This simulation examines fluid flow in a rectangular duct with a
            cylinder, where the Reynolds number is 15,000 based on the duct's
            characteristic length and 3,000 based on the cylinder's diameter.
            The study focuses on the complex flow patterns that emerge,
            including vortex shedding and turbulence, as the fluid interacts
            with the cylindrical obstruction
          </div>
        </div>
      </div>
      <section className="section section--hidden">
      <div className="Home_Cards_Heading">Our Expertise </div>
        <div className="Home_Cards_Grid">
          <HomeCard
            animationLink={health}
            cardHeading={"Software Development"}
            cardContent={"This is a card on Health"}
          />
          <HomeCard
            animationLink={bio}
            cardHeading={"Algorithm Design"}
            cardContent={"This is a card on Biomedical"}
          />
          <HomeCard
            animationLink={oil}
            cardHeading={"High Performance Computing"}
            cardContent={"This is a card on Oil and Gas"}
          />
          <HomeCard
            animationLink={energy}
            cardHeading={"Parallel Computing"}
            cardContent={"This is a card on Renewable Energy"}
          />
          <HomeCard
            animationLink={health}
            cardHeading={"Multi-Physics Simulation"}
            cardContent={"This is a card on Renewable Energy"}
          />
        </div>
        <div className="Home_Cards_Heading">Target Industries </div>
        <div className="Home_Cards_Grid">
          <HomeCard
            animationLink={health}
            cardHeading={"Health"}
            cardContent={"This is a card on Health"}
          />
          <HomeCard
            animationLink={bio}
            cardHeading={"Pharmaceuticals"}
            cardContent={"This is a card on Biomedical"}
          />
          <HomeCard
            animationLink={oil}
            cardHeading={"Oil and Gas"}
            cardContent={"This is a card on Oil and Gas"}
          />
          <HomeCard
            animationLink={energy}
            cardHeading={"Renewable Energy"}
            cardContent={"This is a card on Renewable Energy"}
          />
        </div>

        <Support heading="Our Supporters" supporters={supportersData} />
        {/* <Hoarding /> */}
      </section>
    </>
  );
}

export default Navbar;
