import "./mainbar.css";
import { Link } from "react-router-dom";
import StaticMainbar from "./staticnavbar";
import { useState } from "react";
import video from "../assets/FlowSimulation1.mp4"
export default function Mainbar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <div className="mainbar_whole ">
        <div className="mainbar_top">
          <div className="mainbar_company">
            <Link to="/" className="mainbar_link">
              Rigvedita Technologies
            </Link>
          </div>
          <div className="mainbar_tabs">
            <div className="mainbar_tab">
              <Link to="/team" className="mainbar_link">
                Meet Our Team
              </Link>
            </div>
            <div className="mainbar_tab">
              {" "}
              <li
                className="nav-item dropdown"
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                <ul
                  className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  aria-labelledby="navbarDropdown"
                ></ul>
              </li>
            </div>
          </div>
        </div>
        <div className="mainbar_hidden_navbar">
          <StaticMainbar />
        </div>
        <div className="mainbar_mid ">
          <div className="mainbar_mid_left">
            <div className="mainbar_mid_left_top_left mb-2">
              Welcome to the future of fluid dynamics simulations with Rigvedita Technologies!
            </div>
            <div className="mainbar_mid_left_top_right">
              Here at Rigvedita, our focus is on advancing the development of
              cutting-edge Computational Fluid Dynamics (CFD) solvers. We aim to
              revolutionize the understanding of fluid dynamics through computer
              simulations, staying dedicated to pushing the boundaries of
              innovation in this field. Our main goal is to advance CFD
              technology and offer solutions that are both easy to use and
              highly advanced. We aim to establish a notable presence in the
              industry through the provision of solutions that are both
              straightforward and sophisticated.
            </div>
          </div>
          <div className="mainbar_mid_right">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  {/* <img
                    src="https://www.simscale.com/wp-content/uploads/2023/11/product-page-header-fluid-dynamics.png"
                    className="d-block"
                    alt="..."
                  /> */}
                  <video src={video} autoPlay loop muted width="500">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
