import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./pages/Navbar";
import Footer from "./pages/footer";
import Team from "./pages/Team";

function App() {
  return (
    <>
      <Router>
          <div className="containerdd">
            <Routes>
              <Route exact path="/" element={<Navbar />} />
              <Route exact path="/team" element={<Team />} />
            </Routes>
            <Footer />
          </div>
      </Router>
    </>
  );
}

export default App;
